import _ from 'lodash';

var ambientes = ['localhost','test','testecs','preprod', 'https://sep.colonseguros.com.ar']
var nombresAmbientes = {
    'localhost': 'preprod',
    'test': 'test',
    'testecs': 'testecs',
    'preprod': 'preprod', 
    'https://sep.colonseguros.com.ar': ''
}


var API_URLS = {
    //'localhost': 'http://localhost:9000',
    'localhost': 'https://seppreprod.colonseguros.com.ar',
    'test': 'https://septest.colonseguros.com.ar',
    'testecs': 'https://septestecs.colonseguros.com.ar',
    'preprod': 'https://seppreprod.colonseguros.com.ar',
    'https://sep.colonseguros.com.ar': 'https://sep.colonseguros.com.ar',
}

var API_URLS_FRONT = {
    'localhost': 'http://localhost:3000',
    'test': 'https://septest.colonseguros.com.ar',
    'testecs': 'https://septestecs.colonseguros.com.ar',
    'preprod': 'https://seppreprod.colonseguros.com.ar',
    'https://sep.colonseguros.com.ar': 'https://sep.colonseguros.com.ar',
}

function getURL(ambiente){
    //console.log("ambiente: ", ambiente);
    return API_URLS[ambiente];
}

function getNombreAmbiente(ambiente){
    //console.log("ambiente: ", ambiente);
    return nombresAmbientes[ambiente];
}

function getURLFront(ambiente){
    //console.log("ambiente: ", ambiente);
    return API_URLS_FRONT[ambiente];
}

function getAmbiente(){

    var fullPath = window.location.href
    //console.log(fullPath)
    return _.find(ambientes, function(ambiente) {
        return _.includes(fullPath, ambiente)
    });

}

export const API_URL = getURL(getAmbiente());
export const API_URL_FRONT = getURLFront(getAmbiente());

export const TOKEN_BASIC = 'YnJvd3NlckNsaWVudElkUGFzc3dvcmQ6c2VjcmV0';

export const COOKIE_EXPIRE_TIME = 1000*60*60*10; //millis segundos minutos horas
// export const COOKIE_EXPIRE_TIME = 1000*60; //1 minuto de vida para pruebas

let urlLogin = "https://seguros.colonseguros.com.ar/ov_consultas/#/login"
let apiUrlService = API_URL
let ambiente = getNombreAmbiente(getAmbiente())
let cookieDns = ".colonseguros.com.ar"
let urlHomeDefault = API_URL + "/home/"
let urlHomeTelemarketing = API_URL + "/home/"

if(getAmbiente() === "localhost"){
    urlLogin = "https://seguros.colonseguros.com.ar/ov_consultas/#/login"
    ambiente = "preprod" //test: "test" preprod: "preprod" production: ""
    apiUrlService = 'https://sep' + ambiente + '.colonseguros.com.ar'
    cookieDns = ""
    urlHomeDefault =  "http://localhost:9101/"
    urlHomeTelemarketing =  "http://localhost:9102/"
}

export const URL_LOGIN = urlLogin
export const API_URL_SERVICE = apiUrlService
export const AMBIENTE = ambiente
export const COOKIE_DNS= cookieDns
export const URL_HOME_DEFAULT = urlHomeDefault
export const URL_HOME_TELEMARKETING = urlHomeTelemarketing
export const tmpparaquenomuestrewarning = getNombreAmbiente(getAmbiente())
